import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


/**Import Styles and Assets */
import styles from "./PrivacyPolicy.module.scss";

import { fetchStaticTextThunk, updateStaticTextThunk } from "../../features/StaticTextReducer";

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ContentContainer from "../../components/ContentContainer";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function PrivacyPolicy() {
    const { data, status } = useSelector(state => state.staticText);
    const dispatch = useDispatch();

    const [isEditable, setIsEditable] = useState(false);
    const inputRef = useRef(null);

    const options = useMemo(() => {
        return !isEditable ?
            <button
                onClick={() => { setIsEditable(true); inputRef.current.focus(); }}
                className={`${styles.pageOptions} ${styles.edit}`}
            >
                Edit
            </button> :
            <div className={styles.save}>
                <button onClick={handleSave} className={`${styles.pageOptions} ${styles.save}`}>Save</button>
                <button onClick={cancelEdit} className={`${styles.pageOptions} ${styles.cancel}`}>Cancel</button>
            </div>
    }, [isEditable])

    useEffect(() => {
        if (status !== "success") {
            dispatch(fetchStaticTextThunk());
        }
    }, [])

    useEffect(() => {
        if (status === "success") {
            if (inputRef.current) {
                inputRef.current.value = data?.privacy_policy ?? '';
            }
            setIsEditable(false);
        }

    }, [data.privacy_policy])

    function cancelEdit() {
        setIsEditable(false);
        inputRef.current.value = data?.privacy_policy;
    }

    function handleSave() {
        dispatch(updateStaticTextThunk({ privacy_policy: inputRef.current.value }));
    }




    return (
        <ContentContainer hasOptions={true} hasOptionsAfter={true} optionsAfter={options}>
            <div className={styles.privacyPolicy}>
                <div className={styles.content}>
                    {(status !== 'pending') ? <textarea readOnly={!isEditable} ref={inputRef} /> : <div className={'loadingContainer'}>
                        <Spin indicator={antIcon} style={{ fontSize: "24px" }} />
                    </div>}
                </div>

            </div>
        </ContentContainer>

    )
}