import { Axios } from "../hooks/useAxiosInstance";

export const Vendor = (function () {


    const apis = () => ({
        get: async (page, limit, api_token) => await getRecord(page, limit, api_token),
        create: async (data, api_token) => await createRecord(data, api_token),
    })


    async function getRecord(page, limit, api_token) {
        const url = `vendor?page=${page}&limit=${limit}`;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.get(url, option);
    }

    async function createRecord(data, api_token) {
        const url = `vendor`;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.post(url, data, option);
    }


    return apis();

})()
