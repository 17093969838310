import React, { useState } from "react";


import BannerCards from "./BannerCards";
import AddBannerManagement from "./AddBannerManagement";


export default function PromotionBanner() {
    const [page, setPage] = useState('lists');

    const handleAddButton = () => {
        setPage("add")
    }
    const handleBackButton = () => {
        setPage("lists")
    }
    return (page == 'lists') ? <BannerCards handleAddButton={handleAddButton} /> : <AddBannerManagement handleBackButton={handleBackButton} />
}
