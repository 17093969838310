import React, { useMemo } from "react";

import styles from "./index.module.scss"
import { ReactComponent as DashboardLogo } from "./images/dashboard1.svg"

import ContentContainer from "../../components/ContentContainer";
import { Dashboard } from "../../Api/Dashboard";
import { Toast } from "../../hooks/useToast";

import { Col, Row } from "antd";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

const antIcon = <LoadingOutlined style={{ fontSize: 35, color: "white" }} spin />;

export default function DashBoard() {

    const { api_token } = useSelector(state => state.profile.data)
    const { data, isLoading } = useQuery({
        queryKey: ['dashboard'],
        queryFn: () => Dashboard.get(api_token),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
        onError: (err) => Toast(err.message, 'error', false)
    })
    const item = useMemo(() => [
        {
            // image: dash1,
            number: data?.data.user_count || 0,
            info: "Number of Users",
            style: { backgroundImage: `linear-gradient(180deg, #BA2D52, #D93B30)` },
        },
        {
            // image: dash1,
            number: data?.data.vendor_count || 0,
            info: "Number of Vendors",
            style: { backgroundImage: `linear-gradient(rgb(195, 55, 100), rgb(29, 38, 113))` },
        },
    ], [data?.data.user_count, data?.data.vendor_count])

    return (
        <ContentContainer hasOptions={false}>
            <div className={styles.cardContainer}>
                <Row gutter={[16, 16]} className={styles.row}>
                    {item.map((i, index) => {
                        return <Col xxl={6} xl={8} lg={8} sm={12} index={index} key={index}>
                            <div className={styles.card} style={i.style}>
                                <div className={styles.imageBack} >
                                    <DashboardLogo />
                                </div>
                                <h1>{isLoading ? <Spin indicator={antIcon} size="large" /> : i.number}</h1>
                                <h4>{i.info}</h4>
                            </div>
                        </Col>
                    })}
                </Row>
            </div>
        </ContentContainer>
    )
}