import React, { useState } from "react";
import SpotLists from "./SpotLists";
import BannerDescription from "./BannerDescription";



export default function AddBannerManagement({ handleBackButton }) {
    const [page, setPage] = useState('lists');
    const [details, setDetails] = useState({
        spot_slug: '',
        description: '',
        image_url: {},
    })

    const handleBackButtonClick = () => {
        setPage('lists')
    }


    return (page == 'lists') ? <SpotLists handleBackButton={handleBackButton} setDetails={setDetails} setPage={setPage} />
        : <BannerDescription details={details} setDetails={setDetails} handleBackButton={handleBackButtonClick} handleFullBack={handleBackButton} />

}