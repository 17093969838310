import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Profile } from '../Api/Profile';
import { Toast } from '../hooks/useToast';
import { updateKey } from './DefaultReducer';
import CryptoJS from 'crypto-js';

export const postProfileThunk = createAsyncThunk(
    'profile/login',
    async ({ data, remember_me }, { dispatch }) => {
        Toast("Validating Credentials", "loading", true);
        try {
            data.device_type = 'web'
            data.device_token = 'admin_token_123'
            const response = await Profile.post(data);
            dispatch(updateKey({ isLoggedIn: true }));
            if (remember_me) {
                const encrypted = CryptoJS.AES
                    .encrypt(JSON.stringify(response.data), process.env.REACT_APP_SECRET_KEY).toString();
                localStorage.setItem(process.env.REACT_APP_WEB_STORAGE_TOKEN, encrypted);

            }
            Toast(response.message, "success", false);
            return response.data
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message)
        }
    }
)

export const postForgetpasswordThunk = createAsyncThunk(
    'profile/forgot-password',
    async ({ email, setLoginForm }, { dispatch }) => {
        Toast("Sending Email", "loading", true);
        try {
            const response = await Profile.postForgetPassword({ email });
            Toast(response.message, "success", false);
            setLoginForm(true);
            return {}
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message)
        }
    }
)

export const updateProfileThunk = createAsyncThunk(
    'profile/update',
    async ({ data, setIsSubmitted }, { getState }) => {
        Toast("Updating Profile", "loading", true);
        try {
            const { slug, api_token } = getState().profile.data;
            const response = await Profile.update(data, slug, api_token);
            const token = localStorage.getItem(process.env.REACT_APP_WEB_STORAGE_TOKEN);
            if (token) {
                const encrypted = CryptoJS.AES
                    .encrypt(JSON.stringify(response.data), process.env.REACT_APP_SECRET_KEY).toString();
                localStorage.setItem(process.env.REACT_APP_WEB_STORAGE_TOKEN, encrypted);
            }

            Toast('Profile Updated Successfully', 'success', false)
            return response.data
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message)
        }
        finally {
            setIsSubmitted(false);
        }
    }
)

export const updateProfilePasswordThunk = createAsyncThunk(
    'profile/update/password',
    async ({ data, handleClose }, { getState }) => {
        Toast("Updating Password", "loading", true);
        try {
            const { api_token } = getState().profile.data;
            const response = await Profile.updatePassword(data, api_token);
            Toast(response.message, "success", false);
            handleClose()
            const token = localStorage.getItem(process.env.REACT_APP_WEB_STORAGE_TOKEN);
            if (token) {
                const encrypted = CryptoJS.AES
                    .encrypt(JSON.stringify(response.data), process.env.REACT_APP_SECRET_KEY).toString();
                localStorage.setItem(process.env.REACT_APP_WEB_STORAGE_TOKEN, encrypted);
            }
            return response.data
        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err.message)
        }
    }
)


const initialState = {
    status: "pending",
    data: {},
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setStatus: (state, actions) => {
            return {
                ...state,
                ...actions.payload
            }
        },
        updateKeys: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        resetState: () => {
            return initialState
        }
    },
    extraReducers: {
        [postProfileThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [postProfileThunk.pending]: (state, action) => {
            state.status = "loading"
        },
        [postProfileThunk.rejected]: (state, action) => {
            state.status = "error"
        },

        [updateProfileThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [updateProfileThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [updateProfileThunk.rejected]: (state, action) => {
            state.status = "success"
        },

        [updateProfilePasswordThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [updateProfilePasswordThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [updateProfilePasswordThunk.rejected]: (state, action) => {
            state.status = "success"
        },
    }
})


// Action creators are generated for each case reducer function
export const { setStatus, resetState, updateKeys } = profileSlice.actions

export default profileSlice.reducer