import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';

import 'antd/dist/antd.min.css';

import { useLocalStorage } from "./hooks/useLocalStorage";

import ProtectedLayout from "./components/ProtectedLayout";
import ContentLayout from "./components/ContentLayout";

import PublicRoute from "./components/Routes/PublicRoute";
import ProtectedRoute from "./components/Routes/ProtectedRoute";

import Login from "./pages/Login";
import ChangePassword from "./pages/ChangePassword";
import Dashboard from "./pages/Dashboard";
import Vendor from "./pages/Vendor";
import PromotionBanner from "./pages/PromotionBanner";
import Authenticate from "./pages/Authenticate";
import TermsAndCondition from "./pages/TermsAndCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import About from "./pages/About";
import Lookups from "./pages/Lookups";
import UserManagement from "./pages/UserManagement";




function App() {
  const user = useLocalStorage();

  return (
    <Router>
      <Routes>
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/login`} element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/dashboard`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='0' isSearchBar={true}>
              <ContentLayout>
                <Dashboard />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/vendors`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='1' isSearchBar={true}>
              <ContentLayout>
                <Vendor />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/user-management`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='2' isSearchBar={true}>
              <ContentLayout>
                <UserManagement />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/featured-banners`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='3' isSearchBar={true}>
              <ContentLayout>
                <PromotionBanner />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/authenticate`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='4' isSearchBar={true}>
              <ContentLayout>
                <Authenticate />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/tags-management`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='5' isSearchBar={true}>
              <ContentLayout>
                <Lookups />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/terms-and-conditions`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='6' isSearchBar={true}>
              <ContentLayout>
                <TermsAndCondition />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/privacy-policy`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='7' isSearchBar={true}>
              <ContentLayout>
                <PrivacyPolicy />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/about`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='8' isSearchBar={true}>
              <ContentLayout>
                <About />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        {/* <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/change-password`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='3' isSearchBar={true}>
              <ContentLayout>
                <ChangePassword />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } /> */}
        <Route path={`${process.env.REACT_APP_DOMAIN_DIR}/*`} element={<Navigate to={`${process.env.REACT_APP_DOMAIN_DIR}/login`} />} />
      </Routes >


    </Router >
  );
}

export default App;
