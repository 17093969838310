import React, { useEffect, useMemo, useState } from "react";

import styles from "./EditLookup.module.scss"

import ModalContainer from "../../components/ModalContainer.js";
import { addLookupsThunk, removeLookupsThunk, reorderLookupsThunk } from "../../features/LookupReducer";
import { useDispatch, useSelector } from "react-redux";

import { Button, Input, Spin } from "antd";
import { ImCancelCircle } from "react-icons/im"
import { Toast } from "../../hooks/useToast";
import { LoadingOutlined } from '@ant-design/icons';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { confirmAlert } from "react-confirm-alert";

const SortableItem = SortableElement(({ value, index, handleRemoveTag }) => {
    return <p
        tabIndex={0}
        index={index}
        className={styles.tag}
    >
        {value}
        <span className={styles.removeTag} onClick={handleRemoveTag}><ImCancelCircle /></span>
    </p>
});

const SortableList = SortableContainer(({ items, handleRemoveTag }) => {
    return (
        <div>
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} handleRemoveTag={() => handleRemoveTag(value)} />
            ))}
        </div>

    );
});

const antIcon = <LoadingOutlined spin />;


export default function EditLookup({ lookupData, handleClose }) {
    const { data: lookups } = useSelector(state => state.lookups);
    const [tagValue, setTagValue] = useState('');
    const dispatch = useDispatch();
    const [data, setData] = useState(lookupData)
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isReordering, setIsReordering] = useState(false)
    const [isReorder, setIsReorder] = useState(false)

    const handleRemoveTag = (value) => {
        if (isDeleting || isSubmitted || isReordering) return;

        confirmAlert({
            title: "Confirm Deletion",
            message: "Are you sure you want to delete tag?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setIsDeleting(true)
                        dispatch(removeLookupsThunk({ key: data.key, value, setIsDeleting, setData }))
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
        });
    }

    useEffect(() => {
        if (!lookupsValueValidaton.values.every((item, index) => data.values.includes(item) && (data.values[index] === item))) {
            setIsReorder(true)
        }
        else {
            setIsReorder(false)
        }
    }, [data.values])

    const handleModalClose = () => {
        if (isDeleting || isSubmitted || isReordering) return;
        handleClose()
    }

    const handleAddTag = () => {
        if (isDeleting || isSubmitted || isReordering) return;

        const value = tagValue.trim();
        if (!value) return;
        if (data.values.map(item => item.toLowerCase()).includes(value.toLowerCase())) return Toast('Tag Already Exists', 'error', false);
        if (value.includes(',')) return Toast('Tag should not contain comma(,)', 'error', false);

        setIsSubmitted(true)
        dispatch(addLookupsThunk({ key: data.key, value, setIsSubmitted, setData, setTagValue }));
    }

    const lookupsValueValidaton = useMemo(() => {
        const searchIndex = lookups.findIndex((item) => item.key === lookupData.key);
        return lookups[searchIndex]
    }, [lookups])

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const movedData = arrayMoveImmutable(data.values, oldIndex, newIndex);
        setData(prev => {
            return {
                ...prev,
                values: movedData
            }
        })
    };

    const handleReorder = () => {
        if (isDeleting || isSubmitted || isReordering) return;
        setIsReordering(true);
        const payload = {};
        payload[data.key] = data.values;
        dispatch(reorderLookupsThunk({ data: payload, setIsReordering, setIsReorder }))
    }

    return (
        <ModalContainer handleClose={handleModalClose}>
            <div className={styles.EditLookup}>
                <h1 className={styles.title}>{data.title}</h1>
                <div className={styles.inputContainer}>
                    <Input placeholder="Add new tags" className={styles.addTagsInput} value={tagValue} onChange={(e) => setTagValue(e.target.value)} onPressEnter={handleAddTag} />
                    <Button className={styles.submit} onClick={handleAddTag}>
                        {isSubmitted ? <Spin indicator={antIcon} /> : 'Add'}
                    </Button>
                </div>
                <div className={styles.tagsContainer}>
                    <SortableList
                        items={data?.values ?? []}
                        onSortEnd={onSortEnd}
                        handleRemoveTag={handleRemoveTag}
                        pressDelay={150}
                        helperClass={styles.sortableHelper}
                    />
                </div>
                {!isReorder ? ""
                    : < button className={styles.save} onClick={handleReorder}>
                        Save
                    </button>
                }

            </div>

        </ModalContainer >
    )
}