import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Lookups } from '../Api/Lookup';
import { Toast } from '../hooks/useToast';

export const getLookupsThunk = createAsyncThunk(
    'lookups/get',
    async (_, { getState }) => {
        try {
            const { api_token } = getState().profile.data;
            const response = await Lookups.get(api_token);
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)

export const removeLookupsThunk = createAsyncThunk(
    'lookups/remove',
    async ({ value, key, setIsDeleting, setData }, { getState }) => {
        Toast("Removing Tag", "loading", true);
        try {
            const { api_token } = getState().profile.data;
            const response = await Lookups.delete(key, value, api_token);
            Toast('Tag deleted successfully', "success", false);
            setData(prev => {
                return {
                    ...prev,
                    values: prev.values.filter(tag => tag !== value)
                }
            })
            return {
                key,
                value
            }
        }
        catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
        finally {
            setIsDeleting(false);
        }
    }
)

export const addLookupsThunk = createAsyncThunk(
    'lookups/add',
    async ({ value, key, setIsSubmitted, setData, setTagValue }, { getState }) => {
        Toast("Adding Tag", "loading", true);
        try {
            const { api_token } = getState().profile.data;
            const response = await Lookups.update(key, value, api_token);
            Toast('Tag added successfully', "success", false);
            setTagValue('')
            setData(prev => {
                return {
                    ...prev,
                    values: [...prev.values, value]
                }
            })
            return {
                key,
                value
            }
        }
        catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
        finally {
            setIsSubmitted(false);
        }
    }
)
export const reorderLookupsThunk = createAsyncThunk(
    'lookups/reorder',
    async ({ data, setIsReordering, setIsReorder }, { getState }) => {
        Toast("Updating Tag", "loading", true);
        try {
            const { api_token } = getState().profile.data;
            const response = await Lookups.reorder(data, api_token);
            Toast('Tag updated successfully', "success", false);
            setIsReorder(false);
            return response.data
        }
        catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
        finally {
            setIsReordering(false);
        }
    }
)



const initialState = {
    status: "pending",
    data: [],
}

export const LookupSlice = createSlice({
    name: 'lookups',
    initialState,
    reducers: {
        setStatus: (state, actions) => {
            return {
                ...state,
                status: actions.payload
            }
        },
        resetState: () => {
            return initialState
        }
    },
    extraReducers: {
        [getLookupsThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [getLookupsThunk.pending]: (state, action) => {
            state.status = "pending"
        },
        [getLookupsThunk.rejected]: (state, action) => {
            state.status = "rejected"
        },
        [addLookupsThunk.fulfilled]: (state, action) => {
            const { key, value } = action.payload;
            state.data = state.data.map(item => {
                if (item.key !== key) return item;
                return {
                    ...item,
                    values: [...item.values, value]
                }
            })
            state.status = "success"
        },
        [addLookupsThunk.pending]: (state, action) => {
            state.status = "pending"
        },
        [addLookupsThunk.rejected]: (state, action) => {
            state.status = "rejected"
        },
        [reorderLookupsThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [reorderLookupsThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [reorderLookupsThunk.rejected]: (state, action) => {
            state.status = "success"
        },
        [removeLookupsThunk.fulfilled]: (state, action) => {
            const { key, value } = action.payload;
            state.data = state.data.map(item => {
                if (item.key !== key) return item;
                return {
                    ...item,
                    values: item.values.filter(tag => tag !== value)
                }
            })
            state.status = "success"
        },
        [removeLookupsThunk.pending]: (state, action) => {
            state.status = "pending"
        },
        [removeLookupsThunk.rejected]: (state, action) => {
            state.status = "rejected"
        },


    }

})


// Action creators are generated for each case reducer function
export const { setStatus, resetState } = LookupSlice.actions
export default LookupSlice.reducer