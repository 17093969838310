import React, { useState } from "react";

import styles from "./styles/BannerDescription.module.scss";
import ContentContainer from "../../components/ContentContainer";
import { Toast } from "../../hooks/useToast";

import { Input, Upload } from "antd";
import { AiOutlinePlus } from "react-icons/ai"
import _ from "lodash"
import { useMutation, useQueryClient } from "react-query";
import { PromotionBanner } from "../../Api/PromotionBanner";
import { useSelector } from "react-redux";

export default function BannerDescription({ details, setDetails, handleBackButton, handleFullBack }) {

    const [isSubmitted, setIsSubmitted] = useState(false);
    const { api_token } = useSelector(state => state.profile.data);
    const queryClient = useQueryClient();
    const createBannerMutation = useMutation({
        mutationFn: async (data) => await PromotionBanner.create(data, api_token),
        onSuccess: () => {
            // queryClient.invalidateQueries({
            //     queryKey: ['banners'],
            //     exact: false,
            //     refetchType: 'all',
            // }, { throwOnError: false, cancelRefetch: true })
            handleFullBack();
            Toast('Banner Created Successfully', 'success', false);

        },
        onError: (err) => {
            Toast(err.message, 'error', false)
            setIsSubmitted(false);
        }
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDetails(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJpgOrPng) {
            Toast('You can only upload JPG/PNG file!', 'error', false);
        }
        else if (!isLt2M) {
            Toast('Image must smaller than 2MB!', 'error', false);
        }
        return isJpgOrPng && isLt2M;
    };

    const handleFileChange = (info) => {
        setDetails(prev => {
            return {
                ...prev,
                image_url: info.file.originFileObj
            }
        });

    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const handleSubmit = () => {
        if (isSubmitted) return;

        if (_.isEmpty(details.image_url) || !details.description)
            return Toast('Field should not be empty', 'error', false)

        setIsSubmitted(true);
        Toast('Creating New Banner', 'loading', true);
        const formData = new FormData();
        formData.append('image_url', details.image_url)
        formData.append('description', details.description)
        formData.append('spot_slug', details.spot_slug)
        createBannerMutation.mutate(formData)

    }
    return (
        <ContentContainer hasOptions={true} hasBackButton={true} hasDeleteButton={false} hasEditButton={false} hasAddButton={false} handleBackButtonClick={handleBackButton}>
            <div className={styles.bannerDescription}>
                <h1 className={styles.title}>Enter Banner Details</h1>
                <Input name="description" value={details.description} onChange={handleInputChange} className={styles.description} size="large" placeholder="Banner Description" />

                <Upload
                    name="image_url"
                    listType="picture-card"
                    className={`avatar-uploader ${styles.imageContainer}`}
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    accept="image/jpeg,image/png,image/png"
                    value={_.isEmpty(details.image_url) ? '' : URL.createObjectURL(details.image_url)}
                    onChange={handleFileChange}
                    customRequest={dummyRequest}
                >
                    {!_.isEmpty(details.image_url) ?
                        <img
                            src={URL.createObjectURL(details.image_url)}
                            alt="avatar"
                            style={{ width: '100%' }} /> :
                        <>
                            <span className={styles.uploadIcon}><AiOutlinePlus /></span>
                            <h3>Add Image</h3>
                        </>
                    }
                </Upload>
                <button className={styles.submit} onClick={handleSubmit}>Submit</button>
            </div>
        </ContentContainer >
    )
}