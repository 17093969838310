import { configureStore } from '@reduxjs/toolkit';


import defaultReducer from './features/DefaultReducer';
import ProfileReducer from './features/ProfileReducer';
import SearchReducer from './features/SearchReducer';
import LookupReducer from './features/LookupReducer';
import StaticTextReducer from './features/StaticTextReducer';

export const store = configureStore({
  reducer: {
    default: defaultReducer,
    profile: ProfileReducer,
    search: SearchReducer,
    lookups: LookupReducer,
    staticText: StaticTextReducer,
  },
})