import React, { useCallback, useEffect, useMemo, useState } from "react";

/**Import Styles anad Assets */
import styles from "./index.module.scss"

/**Import Custom Components */
import ContentContainer from "../../components/ContentContainer";
import { getLookupsThunk } from "../../features/LookupReducer";
import EditLookup from "./EditLookup";

/**Import Packaged Components */
import { useDispatch, useSelector } from "react-redux";
import { AiFillEdit } from "react-icons/ai";

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function Lookups() {
    const dispatch = useDispatch();
    const { data, status } = useSelector(state => state.lookups)
    const [editLookup, setEditLookup] = useState(null)

    useEffect(() => {
        if (status !== 'success') {
            dispatch(getLookupsThunk());
        }

    }, [])

    const handleModalClose = useCallback(() => {
        setEditLookup(null);
    }, [])
    // const [data, setData] = useState({
    //     ethnicity: ['Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test'],
    //     looking_for: ['Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test']
    // })
    return (
        <ContentContainer>
            {editLookup ? <EditLookup lookupData={editLookup} handleClose={handleModalClose} /> : ''}
            {(status === 'pending') ? <div className={'loadingContainer'}>
                <Spin indicator={antIcon} style={{ fontSize: "24px" }} />
            </div>
                : ((status !== 'success') || !data.length) ? <div className='emptyContainer'>
                    <h1 className='title'>No Data Found</h1>
                </div> :
                    <div className={styles.lookupsContainer}>
                        {data.map((item, index) => {
                            return <div
                                key={item.key}
                                className={`${styles.lookup} ${(index != (data.length - 1)) ? styles.bottomMargin : ''}`}
                            >
                                <div className={styles.headerContainer}>
                                    <h1 className={styles.title}>{item.title}</h1>
                                    <span
                                        onClick={() => setEditLookup(item)}
                                    >
                                        <AiFillEdit />
                                    </span>
                                </div>
                                <div className={styles.tagsContainer}>
                                    {(item.values).map((tag, index) => {
                                        return <p
                                            className={styles.tag}
                                            key={`tag_${index}`}
                                        >
                                            {tag}
                                        </p>
                                    })}

                                </div>
                            </div>
                        })}
                    </div>}
        </ContentContainer>
    )
}