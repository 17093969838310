import React, { useState } from "react";
import VendorList from "./VendorList";
import AddVendor from "./AddVendor";



export default function Vendor() {

    const [page, setPage] = useState('list')

    const handleAddButton = () => {
        setPage('add')
    }
    const handleBackButton = () => {
        setPage('list')
    }

    return (page == 'list') ? <VendorList handleAddButton={handleAddButton} /> : <AddVendor handleBackButton={handleBackButton} />

}