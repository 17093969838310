import { Axios } from "../hooks/useAxiosInstance";

export const Lookups = (function () {

    // const { api_token } = useSelector(state => state.profile);

    const apis = () => ({
        get: async (api_token) => await getRecord(api_token),
        update: async (key, value, api_token) => await updateRecord(key, value, api_token),
        reorder: async (key, value, api_token) => await reorderRecord(key, value, api_token),
        delete: async (key, value, api_token) => await deleteRecord(key, value, api_token),
    })



    async function getRecord(api_token) {
        const url = `admin/lookups`;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.get(url, option);
    }

    async function updateRecord(key, value, api_token) {
        const url = `admin/lookups?key=${key}&value=${value}`;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.patch(url, {}, option);
    }
    async function reorderRecord(data, api_token) {
        const url = `admin/lookups/reorder`;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.patch(url, data, option);
    }

    async function deleteRecord(key, value, api_token) {
        const url = `admin/lookups?key=${key}&value=${value}`;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.delete(url, option);
    }

    return apis();

})()
