import { Axios } from "../hooks/useAxiosInstance";

export const Spot = (function () {

    const apis = () => ({
        get: async (page, limit, api_token) => await getRecord(page, limit, api_token),
    })


    async function getRecord(page, limit, api_token) {
        const url = `admin/spots?page=${page}&limit=${limit}`;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.get(url, option);
    }

    return apis();

})()
