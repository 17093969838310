import { Axios } from "../hooks/useAxiosInstance";

export const PromotionBanner = (function () {


    const apis = () => ({
        create: async (data, api_token) => await createRecord(data, api_token),
        get: async (page, api_token) => await getRecord(page, api_token),
        delete: async (data, api_token) => await deleteRecord(data, api_token),
    })


    async function createRecord(data, api_token) {
        const url = "admin/promotion-banner";
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'multipart/form-data'
            }
        }

        return await Axios.post(url, data, option);
    }

    async function getRecord(page, api_token) {
        const url = `admin/promotion-banner?page=${page}`;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.get(url, option);
    }


    async function deleteRecord(slug, api_token) {
        const url = "admin/promotion-banner/" + slug;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.delete(url, option);
    }

    return apis();

})()
