import React, { useState } from "react";

import styles from "./styles/SpotList.module.scss"
import ContentContainer from "../../components/ContentContainer";
import { useSpotHeader } from "../../schemas/tableHeaderSchema";
import { Spot } from "../../Api/Spot";

import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { Toast } from "../../hooks/useToast";
import TableLayout from "../../components/TableLayout";

export default function SpotLists({ setDetails, setPage, handleBackButton }) {
    const PAGE_SIZE = 8;

    const [pageNo, setPageNo] = useState(1);

    const header = useSpotHeader(setDetails,setPage);
    const { api_token } = useSelector(state => state.profile.data)

    const { data, isLoading } = useQuery({
        queryKey: ['spots', pageNo],
        queryFn: () => Spot.get(pageNo, PAGE_SIZE, api_token),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
        onError: (err) => Toast(err.message, 'error', false)
    })


    const handlePageChange = (page) => {
        setPageNo(page)
    }

    return (
        <ContentContainer hasOptions={true} hasBackButton={true} hasDeleteButton={false} hasEditButton={false} hasAddButton={false} handleBackButtonClick={handleBackButton}>
            <div className={styles.spotList}>
                <h1>Select Spot For Promotion Banner</h1>
                <TableLayout
                    isLoading={isLoading}
                    data={data?.data ?? []}
                    headers={header}
                    totalRecord={data?.links?.total_records ?? 0}
                    pageNo={pageNo}
                    handlePageChange={handlePageChange}
                    pageSize={PAGE_SIZE}
                />
            </div>
        </ContentContainer>
    )
}