import React, { useEffect, useState } from "react";

import styles from "./styles/VendorList.module.scss"

import ContentContainer from "../../components/ContentContainer";
import { Vendor } from "../../Api/Vendor";
import { useSelector } from "react-redux";
import { Toast } from "../../hooks/useToast";
import { useQuery, useQueryClient } from "react-query";
import TableLayout from "../../components/TableLayout";
import { vendorListing } from "../../schemas/tableHeaderSchema";


export default function VendorList({ handleAddButton }) {
    const PAGE_SIZE = 8;
    const [pageNo, setPageNo] = useState(1);
    const { api_token } = useSelector(state => state.profile.data)
    const { data, isLoading } = useQuery({
        queryKey: ['vendors', pageNo],
        queryFn: () => Vendor.get(pageNo, PAGE_SIZE, api_token),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
        onError: (err) => Toast(err.message, 'error', false)
    })


    const handlePageChange = (page) => {
        setPageNo(page)
    }


    return (
        <ContentContainer hasOptions={true} hasBackButton={false} hasDeleteButton={false} hasEditButton={false} hasAddButton={true} handleAddButtonClick={handleAddButton} >
            <div className={styles.vendorList}>
                <TableLayout
                    isLoading={isLoading}
                    data={data?.data ?? []}
                    headers={vendorListing}
                    totalRecord={data?.links?.total_records ?? 0}
                    pageNo={pageNo}
                    handlePageChange={handlePageChange}
                    pageSize={PAGE_SIZE}
                />
            </div>
        </ContentContainer>
    )
}