import React, { useState } from "react";

import styles from "./styles/AddVendor.module.scss";

import ContentContainer from "../../components/ContentContainer";
import { Input } from "antd";
import { Vendor } from "../../Api/Vendor";
import { useSelector } from "react-redux";
import { Toast } from "../../hooks/useToast";
import { useMutation, useQueryClient } from "react-query";




export default function AddVendor({ handleBackButton }) {

    const [email, setEmail] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { api_token } = useSelector(state => state.profile.data)
    const queryClient = useQueryClient();
    const createVendorMutation = useMutation({
        mutationFn: async (data) => await Vendor.create(data, api_token),
        onSuccess: () => {
            Toast('Vendor Created Successfully', 'success', false);
            queryClient.invalidateQueries({
                queryKey: ['vendors'],
                exact: false,
                refetchType: 'all',
            }, { throwOnError: false, cancelRefetch : true })
            handleBackButton();
        },
        onError: (err) => {
            Toast(err.message, 'error', false)
            setIsSubmitted(false);
        }
    })


    const handleSubmit = () => {
        if (isSubmitted) return;
        if (!email)
            return Toast("Email should not be empty", 'error', false);

        setIsSubmitted(true);
        const body = {
            email: email,
        }

        Toast('Creating Vendor', 'loading', true);
        createVendorMutation.mutate(body)

    }


    return (
        <ContentContainer hasOptions={true} hasBackButton={true} hasDeleteButton={false} hasEditButton={false} hasAddButton={false} handleBackButtonClick={handleBackButton} >
            <div className={styles.addVendor}>
                <h1 className={styles.title}>Create New Vendor</h1>
                <Input value={email} onChange={(e) => setEmail(e.target.value)} className={styles.email} size="large" placeholder="Vendor Email" />
                <button className={styles.submit} onClick={handleSubmit}>Submit</button>
            </div>
        </ContentContainer>
    )

}