import { Axios } from "../hooks/useAxiosInstance";

export const StaticText = (function () {

    const apis = () => ({
        fetch: async ({ api_token }) => await fetchRecord(api_token),
        update: async ({ data, api_token }) => await updateRecord(data, api_token),
    })

    async function fetchRecord(api_token) {
        const url = "admin/static-page";
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.get(url, option);
    }

    async function updateRecord(data, api_token) {
        const url = `admin/static-page`;
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.patch(url, data, option);
    }

    return apis();

})()
