
import { confirmAlert } from 'react-confirm-alert';
import moment from "moment"
import { authenticateStatusEnum } from '../constants';


export function submit(title, message, cb, rejectCB = () => { }) {
    confirmAlert({
        title,
        message,
        buttons: [
            {
                label: 'Yes',
                onClick: () => cb()
            },
            {
                label: 'No',
                onClick: () => rejectCB()
            }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
    });
}

export function useReservationHeader({ status, setDetails, setMainPage }) {
    return [
        {
            title: 'User Name',
            render: (record, val) => {
                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <img src={val.user_image} alt="User Image" style={{ width: "30px", height: "30px", borderRadius: "100%" }} />
                    <p style={{ color: "black", margin: "0 15px", display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.user_name}</p>

                </div>
            }
        },
        {
            title: 'Spot Name',
            render: (record, val) => {
                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <img src={val.spot_image} alt="User Image" style={{ width: "30px", height: "30px", borderRadius: "100%" }} />
                    <p style={{ color: "black", margin: "0 15px", display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.spot_name}</p>

                </div >
            }

        },
        {
            title: 'Date',
            align: "center",
            render: (record, val) => {
                return <p style={{ color: "black", margin: "0", whiteSpace: 'nowrap', textAlign: 'center' }}>{moment(val.date).format('MMM DD, YYYY')}</p>
            }
        },
        {
            title: 'Time',
            align: "center",
            render: (record, val) => {
                return <p style={{ color: "black", margin: "0", whiteSpace: 'nowrap', textAlign: 'center' }}>{moment(val.time, 'hh:mm:ss').format('hh:mm A')}</p>
            }
        },
        {
            render: (val) => {
                return <a style={{ whiteSpace: 'nowrap', display: 'flex', justifyContent: 'center' }} onClick={() => {
                    setDetails(val);
                    setMainPage(status)
                }}>View Details</a>
            }
        },
    ];

}



export const vendorListing = [
    {
        title: 'User Name',
        render: (record, val) => {
            return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                <img src={val.image_url} alt="User Image" style={{ width: "30px", height: "30px", borderRadius: "100%" }} />
                <p style={{ color: "black", margin: "0 15px", display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.username}</p>

            </div>
        }
    },
    {
        title: 'Email',
        dataIndex: "email"

    },


];

export const useAuthenticateListing = (setPreviewImage) => {
    const ENUM = authenticateStatusEnum;
    const RECORD_STYLE = {
        [ENUM.ACCEPTED]: {
            color: "rgb(57 193 16)",
            value: "Accepted"
        },
        [ENUM.REJECTED]: {
            color: "#CB0C51",
            value: "Rejected"
        },
        [ENUM.PENDING]: {
            color: "GREY",
            value: "Pending"
        },
    }
    const handleClick = (val) => {
        if (val.status !== ENUM.PENDING) return;
        setPreviewImage(prev => {
            return {
                url: val.image_url,
                slug: val.slug
            }
        })
    }
    return [
        {
            title: 'User Name',
            render: (record, val) => {
                return <div
                    style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "fit-content", cursor: "pointer" }}
                    onClick={() => handleClick(val)}>
                    <img src={val.image_url} alt="User Image" style={{ width: "30px", height: "30px", borderRadius: "100%" }} />
                    <p style={{ color: "black", margin: "0 15px", display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.name}</p>
                </div >
            }
        },
        {
            title: 'Status',
            width: 220,
            align: 'center',
            key: 'action',
            render: (record) => {
                const { status } = record;
                return <div style={{ width: "100%", border: "none", textAlign: "center", padding: "3px 15px", display: "flex", alignItems: "center", cursor: ((record.status === ENUM.PENDING) ? "pointer" : "inherit") }}>
                    <p style={{ width: "100%", margin: 0, color: RECORD_STYLE[status].color }} onClick={() => handleClick(record)}>{RECORD_STYLE[status].value}</p>
                </div>

            },
        },


    ];
}


export function useSpotHeader(setDetails, setPage) {
    return [
        {
            title: 'Spot Name',
            render: (record, val) => {
                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <img src={val.banner_image} alt="User Image" style={{ width: "30px", height: "30px", borderRadius: "100%" }} />
                    <p style={{ color: "black", margin: "0 15px", display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.name}</p>

                </div>
            }
        },

        {
            title: 'Address',
            align: "center",
            render: (record, val) => {
                return <p style={{ color: "black", margin: "0", whiteSpace: 'nowrap', textAlign: 'center' }}>{val.address}</p>
            }
        },
        {
            render: (val) => {
                return <a style={{ whiteSpace: 'nowrap', display: 'flex', justifyContent: 'center' }} onClick={() => {
                    setDetails(prev => {
                        return {
                            ...prev,
                            spot_slug: val.slug,
                        }
                    });
                    setPage("description")
                }}>Select</a>
            }
        },
    ];

}



export function useUserManagementHeader(pageNo, handleUpdateStatus) {

    return [
        {
            title: 'Username',
            render: (record, val) => {
                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <img src={val.image_url} alt="User Image" style={{ width: "30px", height: "30px", borderRadius: "100%" }} />
                    <p style={{ margin: "0 15px" }}>{val.name}</p>

                </div>
            }
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            align: "center",
        },
        {
            title: 'Phone Number',
            dataIndex: 'mobile_no',
            align: "center",
            render: (mobile_no) => {
                return <p style={{ margin: 0, textAlign: "center" }}>{mobile_no || '-'}</p>
            }
        },
        {
            title: 'Status',
            width: 170,
            key: 'action',
            render: (record) => {
                const status = record.is_blocked ? 'unblock' : 'block';
                return <div
                    onClick={() =>
                        submit(
                            "Change Status", `Are you sure you want to ${status} this user?`,
                            () => handleUpdateStatus({ slug: record.slug, page: pageNo })

                        )
                    }
                    style={{ width: "100%", cursor: "pointer", background: "black", padding: "3px 15px", display: "flex", alignItems: "center" }}>
                    <a
                        style={{ width: "100%", color: "#7BE35C", marginRight: "30px", color: (record.is_blocked == 0) ? "#7BE35C" : "#CB0C51" }}>{!record.is_blocked ? "Unblock" : "Block"}</a>
                </div>
            },
        },
    ];
}




