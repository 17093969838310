import React, { useEffect, useState } from "react";

import styles from "./styles/BannerCards.module.scss"

import ContentContainer from "../../components/ContentContainer";
import { PromotionBanner } from "../../Api/PromotionBanner";

import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { Toast } from "../../hooks/useToast";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { Col, Row } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { AiTwotoneDelete } from "react-icons/ai"
import { confirmAlert } from "react-confirm-alert";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function BannerCards({ handleAddButton }) {

    const [isSubmitted, setIsSubmitted] = useState(false);

    const { ref, inView } = useInView();
    const queryClient = useQueryClient();
    const { api_token } = useSelector(state => state.profile.data)
    const { data, isFetched, isLoading, isRefetching, hasNextPage, fetchNextPage, isFetchingNextPage } =
        useInfiniteQuery("banners", async ({ pageParam = 1 }) => await PromotionBanner.get(pageParam, api_token), {
            retry: 0,
            refetchOnMount: true,
            refetchOnReconnect: true,
            refetchOnWindowFocus: false,
            onSuccess: () => {
                // setEnabled(false)
            },
            onError: (err) => Toast(err.message, 'error', false),
            getNextPageParam: (lastPage, allPages) => {
                return (lastPage.links.current < lastPage.links.total) ? lastPage.links.current + 1 : false
            },
        });

    const deleteBannerMutation = useMutation({
        mutationFn: async (slug) => await PromotionBanner.delete(slug, api_token),
        onSuccess: (response, slug) => {
            queryClient.setQueryData(["banners"], (res) => {
                return {
                    ...res,
                    pages: res.pages.map(item => {
                        return {
                            ...item,
                            data: item.data.filter(item => item.slug !== slug)
                        }
                    })
                }
            })
            setIsSubmitted(false);
            Toast('Banner Deleted Successfully', 'success', false);
        },
        onError: (err) => {
            Toast(err.message, 'error', false)
            setIsSubmitted(false);
        }
    })


    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [inView, hasNextPage]);

    const handleDeleteBanner = (slug) => {
        if (isSubmitted) return;
        confirmAlert({
            title: "Confirm Deletion",
            message: "Are you sure you want to delete?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setIsSubmitted(true);
                        Toast('Deleting Banner', 'loading', true);
                        deleteBannerMutation.mutate(slug);
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
        });

    }
    return (
        <ContentContainer hasOptions={true} hasBackButton={false} hasDeleteButton={false} hasEditButton={false} hasAddButton={true} handleAddButtonClick={handleAddButton}>
            <Row gutter={[30, 40]}>
                {isFetched && !isLoading && !isRefetching && ((data?.pages ?? []).length ? data.pages[0].data.length ? false : true : true) ? <div className='emptyContainer'>
                    <h1 className='title'>No Data Found</h1>
                </div> :
                    (data?.pages ?? []).map((page, i) => {
                        const halfPage = Math.floor(data.pages.length / 2);
                        return (page?.data ?? []).map((item, index) => {
                            return <Col xxl={6} xl={8} md={12} sm={12} xs={20} className={styles.card} key={index} ref={(i == halfPage && index === Math.floor((page?.data.length - 1) / 2) && hasNextPage) ? ref : undefined}>
                                <div className={styles.cardContent}>

                                    <div className={styles.textContainer}>
                                        <span onClick={() => handleDeleteBanner(item.slug)}><AiTwotoneDelete /></span>
                                        <h3 className={styles.name}>
                                            {item.description}
                                        </h3>

                                    </div>
                                    <div className={styles.imageContainer}>
                                        <img src={item.image_url} alt={`Card Image ${index}`} />
                                    </div>
                                </div>
                            </Col>
                        })
                    })}
            </Row>
            {(isLoading || isFetchingNextPage || isRefetching) ? <div className={'loadingContainer'}>
                <Spin indicator={antIcon} style={{ fontSize: "24px" }} />
            </div> : ''}
        </ContentContainer>

    )
}