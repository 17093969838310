import React, { useCallback, useState } from "react";

import styles from "./styles/index.module.scss"

import ContentContainer from "../../components/ContentContainer";
import { Authenticate } from "../../Api/Authenticate";
import TableLayout from "../../components/TableLayout";
import ModalContainer from "../../components/ModalContainer.js";
import { useAuthenticateListing } from "../../schemas/tableHeaderSchema";

import { Select } from 'antd';
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { Toast } from "../../hooks/useToast";

export default function Authentication() {
    const PAGE_SIZE = 8;
    const queryClient = useQueryClient()
    const [previewImage, setPreviewImage] = useState(null);
    const { api_token } = useSelector(state => state.profile.data)
    const [pageNo, setPageNo] = useState(1)
    const [status, setStatus] = useState('pending');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const options = [
        { value: 'pending', label: 'Pending' },
        { value: 'accepted', label: 'Accepted' },
        { value: 'rejected', label: 'Rejected' },
    ]
    const listing = useAuthenticateListing(setPreviewImage);

    const { data, isLoading } = useQuery({
        queryKey: ['authenticate', 'status', status, pageNo],
        queryFn: () => Authenticate.get(status, pageNo, PAGE_SIZE, api_token),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
        onError: (err) => Toast(err.message, 'error', false)
    })

    const updateAuthenticateMutation = useMutation({
        mutationFn: async ({ slug, body }) => await Authenticate.update(slug, body, api_token),
        onSuccess: async (data) => {
            queryClient.setQueryData(['authenticate', 'status', status, pageNo], (data) => {
                return {
                    ...data,
                    data: data.data.filter(item => item.slug != previewImage.slug)
                }
            })
            queryClient.refetchQueries({ queryKey: ['authenticate', 'status', data?.data?.status, 1], type: 'all', exact: true }, { throwOnError: false, cancelRefetch: false })
            queryClient.invalidateQueries({
                queryKey: ['authenticate', 'status', status],
                exact: false,
                refetchType: 'all',
            }, { throwOnError: false, cancelRefetch: false })

            setPreviewImage(null)
            setIsSubmitted(false)
        },
        onError: (err) => {
            Toast(err.message, 'error', false)
            setIsSubmitted(false)
        }
    })

    const handleStatusChange = useCallback((value) => {
        setPageNo(1);
        setStatus(value)
    }, [])

    const handlePageChange = useCallback((value) => {
        setPageNo(value)
    }, [])

    function handleSubmit(status) {
        if (isSubmitted) return;
        setIsSubmitted(true);
        updateAuthenticateMutation.mutate({ slug: previewImage.slug, body: { status } })
    }

    return (
        <ContentContainer hasOptions={false}>
            {!previewImage ? "" :
                <ModalContainer handleClose={() => setPreviewImage(null)}>
                    <div className={styles.modalContentContainer}>
                        <div className={styles.previewContainer}>
                            <img src={previewImage.url} alt="Preview Image " />
                        </div>
                        <div className={styles.optionsContainer}>
                            <button className={`${styles.accept_reject} ${styles.accept}`} onClick={() => handleSubmit('accepted')}>Accept</button>
                            <button className={`${styles.accept_reject} ${styles.reject}`} onClick={() => handleSubmit('rejected')}>Reject</button>
                        </div>
                    </div>
                </ModalContainer>}
            <div className={styles.authenticateContainer}>
                <div className={styles.headerContainer}>
                    <Select
                        value={status}
                        onChange={handleStatusChange}
                        options={options}
                        className={styles.dropdown}
                        size="large"
                    />
                </div>
                <div className={styles.listingContainer}>
                    <TableLayout
                        isLoading={isLoading}
                        data={data?.data ?? []}
                        headers={listing}
                        totalRecord={data?.links?.total_records ?? 0}
                        pageNo={pageNo}
                        handlePageChange={handlePageChange}
                        pageSize={PAGE_SIZE}
                    />
                </div>
            </div>
        </ContentContainer >
    )
}